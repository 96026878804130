// @flow

import * as React from 'react'
import styled from '@emotion/styled'

import { Hero } from '@toggl/ui'
import { fonts } from '@toggl/style'

import IndexLayout from 'layouts'
import LayoutTrack from 'components/LayoutTrack'
import heroBg from 'images/uploads/homepage-hero-bg.jpg'
import videoSrc from 'images/uploads/bg-video.mp4'

const DownloadsPage = () => {
  const HeroComponent = ({ children }) => {
    return (
      <Hero.Wrapper bgImage={heroBg} bgVideo={videoSrc}>
        {children}

        <Hero.ContentWrapper>
          <Hero.Title centered>
            Everything <br /> you need in <br /> <Rotalic>one</Rotalic> package
          </Hero.Title>
        </Hero.ContentWrapper>
      </Hero.Wrapper>
    )
  }

  return (
    <IndexLayout>
      <LayoutTrack Hero={HeroComponent} />
    </IndexLayout>
  )
}

const Rotalic = styled.span`
  font-family: ${fonts.gtHaptikMediumRotalic};
  color: ${(p) => p.theme.primary};
`
export default DownloadsPage
